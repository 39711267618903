import { faHouse } from '@fortawesome/pro-regular-svg-icons';
import { LngLatBoundsLike } from 'mapbox-gl';
import { useRouter } from 'next/router';
import { useState } from 'react';
import { PopupEvent, ViewState } from 'react-map-gl';
import styled from 'styled-components';
import MapView from '../../components/MapView';
import CustomMarker from '../../components/MapView/CustomMarker';
import CustomPopup from '../../components/MapView/CustomPopup';
import { useAnalytics } from '../../hooks/useAnalytics';
import { PropertyGroupAPIResult, PropertyGroupSearchAPIResult } from '../../types/api/propertyGroupTypes';
import { GeneralCMSResult } from '../../types/cms/generalTypes';
import { LanguageKey } from '../../types/common';
import { getImageFromApiUrl } from '../../utils/http';
import { getPropertyGroupLink } from '../../utils/links';
import DefaultPropertyGroupThumbnail from 'assets/default-property-group-thumbnail.svg';
import { useWhiteLabel } from 'providers/WhiteLabelProvider';

const MapWrapper = styled.div`
  border-radius: 8px;
  overflow: hidden;
`;

const MAP_BOUNDS_PADDING_MULTIPLIER = 0.2;
const MINIMUM_BOUNDS_PADDING = 0.002;

const initialViewState: Omit<ViewState, 'padding'> = {
  latitude: 56,
  longitude: 11,
  bearing: 0,
  pitch: 0,
  zoom: 6,
};

function getBounds(searchResults: PropertyGroupSearchAPIResult[]): LngLatBoundsLike {
  if (searchResults.length === 1) {
    const [
      {
        propertyGroup: {
          location: {
            point: {
              coordinates: [longitude, latitude],
            },
          },
        },
      },
    ] = searchResults;

    return [
      longitude - MINIMUM_BOUNDS_PADDING,
      latitude - MINIMUM_BOUNDS_PADDING,
      longitude + MINIMUM_BOUNDS_PADDING,
      latitude + MINIMUM_BOUNDS_PADDING,
    ];
  }
  const edges = searchResults.reduce(
    (
      accumulator,
      {
        propertyGroup: {
          location: {
            point: {
              coordinates: [longitude, latitude],
            },
          },
        },
      },
    ) => {
      return {
        minLatitude: Math.min(accumulator.minLatitude, latitude),
        maxLatitude: Math.max(accumulator.maxLatitude, latitude),
        minLongitude: Math.min(accumulator.minLongitude, longitude),
        maxLongitude: Math.max(accumulator.maxLongitude, longitude),
      };
    },
    { minLongitude: Infinity, minLatitude: Infinity, maxLongitude: -Infinity, maxLatitude: -Infinity },
  );
  const longitudeLength = edges.maxLongitude - edges.minLongitude;
  const latitudeLength = edges.maxLatitude - edges.minLatitude;

  return [
    edges?.minLongitude - longitudeLength * MAP_BOUNDS_PADDING_MULTIPLIER,
    edges?.minLatitude - latitudeLength * MAP_BOUNDS_PADDING_MULTIPLIER,
    edges?.maxLongitude + longitudeLength * MAP_BOUNDS_PADDING_MULTIPLIER,
    edges?.maxLatitude + latitudeLength * MAP_BOUNDS_PADDING_MULTIPLIER,
  ];
}

type PopupInfo = PropertyGroupAPIResult & { propertiesTotal: number };

interface RentalResultPopupProps {
  generalTexts: GeneralCMSResult;
  popupInfo: PopupInfo;
  onClose: (event: PopupEvent) => void;
}

const RentalResultPopup = ({ generalTexts, popupInfo, onClose }: RentalResultPopupProps) => {
  const { locale } = useRouter();
  const { theme } = useWhiteLabel();
  const {
    name,
    location: {
      point: {
        coordinates: [longitude, latitude],
      },
    },
    images: [firstImage],
    propertiesTotal,
  } = popupInfo;

  return (
    <CustomPopup
      anchor="bottom"
      offset={24}
      latitude={latitude}
      longitude={longitude}
      closeButton={false}
      closeOnMove={true}
      onClose={onClose}
      image={
        firstImage ? (
          getImageFromApiUrl(firstImage.name)
        ) : (
          <DefaultPropertyGroupThumbnail backgroundColor={theme.color.secondaryBackgroundColor} logoColor={theme.color.secondaryTextColorHover} />
        )
      }
      headline={name}
      description={name}
      buttonLink={getPropertyGroupLink(popupInfo, locale as LanguageKey)}
      buttonText={
        propertiesTotal > 0
          ? `${propertiesTotal} ${
              propertiesTotal === 1
                ? generalTexts.Misc.AvailablePropertiesSingular.toLowerCase()
                : generalTexts.Misc.AvailablePropertiesPlural.toLowerCase()
            }`
          : generalTexts.Misc.SeeDetails
      }
    />
  );
};

interface RentalHousingMapProps {
  data?: PropertyGroupSearchAPIResult[];
  generalTexts: GeneralCMSResult;
}

const RentalHousingMap = ({ data, generalTexts }: RentalHousingMapProps) => {
  const [popupInfo, setPopupInfo] = useState<PopupInfo>();

  const bounds: LngLatBoundsLike | undefined = data?.length ? getBounds(data) : undefined;

  const viewState: Omit<ViewState, 'padding'> & { bounds?: LngLatBoundsLike } = {
    ...initialViewState,
    ...(bounds ? { bounds } : {}),
  };

  useAnalytics('Search Map', undefined, 'track', !data);

  return (
    <MapWrapper>
      <MapView initialViewState={viewState} scrollZoom={false}>
        {data &&
          data.map(({ propertyGroup, properties }, index) => {
            const {
              location: {
                point: {
                  coordinates: [longitude, latitude],
                },
              },
            } = propertyGroup;
            return (
              <CustomMarker
                key={index}
                latitude={latitude}
                longitude={longitude}
                onClick={(event) => {
                  event.originalEvent.stopPropagation();
                  setPopupInfo({ ...propertyGroup, propertiesTotal: properties.length });
                }}
                size="big"
                color="green"
                icon={faHouse}
                alt="Propstep"
              />
            );
          })}
        {popupInfo && <RentalResultPopup generalTexts={generalTexts} popupInfo={popupInfo} onClose={() => setPopupInfo(undefined)} />}
      </MapView>
    </MapWrapper>
  );
};

export default RentalHousingMap;
